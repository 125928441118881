import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';  
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';
import { UserRegister } from '../models/user-register.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _httpClient: HttpClient, private _cookieTokenService: CookieTokenService) { }

  getAllUsers() {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/users/list`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  deleteUser(userId: number) {
    return this._httpClient.delete(`${env.url_api}/${env.api_version}/users/user/${userId}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  createUser(user:UserRegister){
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/register`, user, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  createNewUserFromAdmin(user:UserRegister){
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/register/admin`, user, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  getUser(userId: number){
    return this._httpClient.get(`${env.url_api}/${env.api_version}/users/user/id/${userId}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  getUserInfo(userId: number){
    return this._httpClient.get(`${env.url_api}/${env.api_version}/users/userInfo/id/${userId}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  updateUserInfo(userInfo: FormData, userId: number) {
    
    userInfo.append('userId', userId.toString());

    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/update/userInfo`, userInfo, { observe: 'response' as 'response', headers: headers })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  updateUserPassword(userPassword: FormData, userId: number) {
    
    userPassword.append('userId', userId.toString());

    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/update/userPassword`, userPassword, { observe: 'response' as 'response', headers: headers })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }


  updateUserFromAdmin(userProducts: Object){
    // console.log('userService.ts userProducts: ', userProducts, ' - type: ', typeof(userProducts));

    let headers = new HttpHeaders()
    .set('NO-CONTENT-TYPE', 'no-content')
    .set('Accept', 'application/json');

    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/update-user`, userProducts, { observe: 'response' as 'response',headers: headers })
    .pipe(map((response: HttpResponse<any>) => {
      // console.log(response);

      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  sendRecoverPassword(email: string) {
    const body = { email: email }; // Crear un objeto con el email
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/send-recover-password`, body, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
    }));
  }

  updatePasswordByUser(password: string, email: string, token: string) {
    const body = { password: password, email: email, token: token }; // Crear un objeto con el password y el userId
    
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/update-password`, body, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  paymentNotification(email: string) {
    const body = { email: email };
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/payment-notification`, body, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
    }));
  }

  userShippingInfo(shippingData: any){
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/shipping`, shippingData, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

}
