import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopProductsService {
  private dataSubject = new BehaviorSubject<string>('');

  constructor(private _httpClient: HttpClient) { }

  getShopProducts() {
    // Configurar opciones para incluir las cookies de sesión
    const options = { withCredentials: true };

    return this._httpClient.get(`${env.url_api}/${env.api_version}/shop/products`, { ...options, observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getCountryData() {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/shop/countryData`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);

        }));

  }

  getShopProductsSearched(likeString: any) {
    let requestUrl = `${env.url_api}/${env.api_version}/shop/search/products`;

    if (likeString !== null && likeString !== undefined && likeString != "") {
      requestUrl = `${requestUrl}/${likeString}`;
    }

    return this._httpClient.get(requestUrl, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  searchLikeString(data: string): void {
    this.dataSubject.next(data);
  }

  getLikeString(): Observable<string> {
    return this.dataSubject.asObservable();
  }
}
