interface ProductType {
    name: string;
}

class ProductType {
    static data: ProductType[] = [
      {
        name:"Book"
      },
      {
        name:"Magazine"
      }
    ]
}

export { ProductType };