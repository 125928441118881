<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>
</div>
<div mat-dialog-actions>
  <div class="text-center">
    <button mat-button (click)="modalInteractTrue()">{{'confirmation.yes' | translate}}</button>
    <button mat-button [mat-dialog-close]="false">{{'confirmation.no' | translate}}</button>
  </div>
</div>
