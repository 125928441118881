import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileUploadService } from '../../services/file-upload.service';
import { documentsList } from '../../models/documents.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit, OnDestroy {
  documents: documentsList[] = [];
  versionsMap: { [key: string]: string[] } = {};
  selectedVersion: { [key: string]: string } = {};
  uniqueFileNames: string[] = [];
  questions: any[] = [];
  isAdmin: boolean;
  selectedLanguage: string;
  private languageChangeSubscription: Subscription;

  constructor(
    private _fileUploadService: FileUploadService,
    private _pageLoadingService: PageLoadingService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    

    // Obtener el idioma seleccionado desde sessionStorage
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage') || 'es';

    // Suscribirse a los cambios de idioma
    this.languageChangeSubscription = this._translateService.onLangChange.subscribe(event => {
      this.selectedLanguage = event.lang;
      this.loadQuestions();
    });

    // Cargar preguntas iniciales
    this.loadQuestions();
  }

  ngOnDestroy() {
    // Desuscribirse para evitar fugas de memoria
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }

  loadQuestions(): void {
    this._pageLoadingService.showLoadingGif();
    this._fileUploadService.getQuestions().subscribe(
      (data: any[]) => {
        this._pageLoadingService.hideLoadingGif();
        this.questions = data.filter(question => question.language == this.selectedLanguage);
        console.log('Preguntas obtenidas:', this.questions);
      },
      (error: any) => {
        this._pageLoadingService.hideLoadingGif();
        console.error('Error al obtener preguntas:', error);
      }
    );
  }
}
