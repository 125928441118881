import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaypalPaymentService } from '../../services/paypal-payment.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-paypal-confirmation',
  templateUrl: './paypal-confirmation.component.html',
  styleUrls: ['./paypal-confirmation.component.scss']
})
export class PaypalConfirmationComponent {

  paymentId: string;
  payerId: string;
  paymentState: boolean = false;
  processingPayment: boolean = true;

  readonly confirmPaymentObserver = {
    next: (data: CommonResponse<any>) => this.confirmPaymentNext(data),
    error: (error: CommonResponse<any>) => this.confirmPaymentError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(private _route: ActivatedRoute,
    private _paypalService: PaypalPaymentService,
    private _pageLoadingService: PageLoadingService) {
      this._route.queryParams.subscribe(params => {
        this.paymentId = params['paymentId'];
        this.payerId = params['PayerID'];
      });

      this._paypalService.getPaymentConfirmation(this.paymentId, this.payerId)
        .subscribe(this.confirmPaymentObserver);
    }

  confirmPaymentNext(data: CommonResponse<any>) {
    this.paymentState = data.data;
    this.processingPayment = false;
    this.paymentState = true;
  }

  confirmPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this.processingPayment = false;
    // console.log(!this.paymentState && !this.processingPayment);

  }

  closeTab() {
    window.close();
  }

}
