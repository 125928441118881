<div class="main">
    <div class="row">
        <!-- Header -->
        <div class="row headerListBook">
            <div class="col-8 p-2">
                <b class="titleListBook"> {{'footer.data_processing_ft' | translate}} </b>
            </div>
            <div class="col-7"></div>
            <br>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="textDescriptionContainer">
                            <div class="LinksDiv">
                                <div class="line"></div>
                                <div class="list" (click)="scrollToTitle ('license'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Conditions.information' | translate}}
                                </div>
                                <div class="list"
                                    (click)="scrollToTitle ('unauthorizedUse'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Conditions.licesnse' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('como'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Conditions.unauthorized_use' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('legislation'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Conditions.como' | translate}}
                                </div>
                            </div>
                            <p class="descriptionViewListBook">
                            <p class="textDecriptionSize">
                                <strong id="license">{{'Conditions.information' | translate}}</strong>
                                <br><br>
                                {{'Conditions.who' | translate}}
                                <br><br>

                                <strong id="unauthorizedUse">{{'Conditions.licesnse' | translate}}</strong>
                                <br><br>
                                {{'Conditions.information_text' | translate}}
                                <br><br>

                                <strong id="como">{{'Conditions.unauthorized_use' | translate}}</strong>
                                <br><br>
                                {{'Conditions.unauthorized_use_text' | translate}}
                                <br><br>

                                <strong id="legislation">{{'Conditions.como' | translate}}</strong>
                                <br><br>
                                {{'Conditions.como_text' | translate}}
                                <br><br>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="mat-elevation-z8">
                        <ng-container matColumnDef="resume">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <b>{{'create_product.summary_crp' | translate}}</b>
                            </th>
                            <td mat-cell *matCellDef="let element" class="resumecolSize">
                            </td>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>