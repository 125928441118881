import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ListUsersComponent } from './components/list-users/list-users.component';
import { AuthenticatedUserGuard } from '../guards/authenticated-user.guard';
import { RegisterUsersComponent } from './components/register-users/register-users.component';
import { ValidateRoutePermissions } from '../auth/models/route-permission.auth';
import { RecoverPasswordFormComponent } from '../auth/components/recover-password-form/recover-password-form.component';
import { ResetPasswordFormComponent } from '../auth/components/reset-password-form/reset-password-form.component';

const routes: Routes = [
  {
    path: 'users',
    children: [
      { path: 'list', component: ListUsersComponent, canActivate: [AuthenticatedUserGuard], data: {permission: 'list_all_users'} },
      { path:'register', component: RegisterUsersComponent },
      { path: 'recover-password', component: RecoverPasswordFormComponent },
      { path: 'reset-password/:token', component: ResetPasswordFormComponent },
      { path: 'reset-password/:token/:email', component: ResetPasswordFormComponent, pathMatch:'full' }
    ]
  }
];

ValidateRoutePermissions.registerRoutesAndPermissions(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}

