import {  Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { RouteHelper } from 'src/app/shared/helpers/route.helper';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss']
})
export class BasicLayoutComponent {

  showLayout: boolean = true;

  constructor(private _loginService : LoginService,
    private _router: Router) {
      this._router.events.subscribe((event) => {
        if(event instanceof NavigationStart) {
          this.showLayout = RouteHelper.showLayoutForRoute((event as NavigationStart).url);
        }
      });
    }
}
