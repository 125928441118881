import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {

  constructor(private elementRef: ElementRef) { }

  scrollToTitle(elementId: string): void {
    const element = this.elementRef.nativeElement.querySelector('#' + elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  // change of point in index
  toggleCircleClass(event: Event) {
    // Select all elements with 'circle' class and deselect
    const circles = document.querySelectorAll('.circle');
    circles.forEach(circle => {
      circle.classList.remove('clicked'); //Remove class 'clicked' of all
    });

    //Add class 'clicked' to first element '.circle' inside of selected container
    const targetElement = event.currentTarget as HTMLElement;
    const circle = targetElement.querySelector('.circle');
    if (circle) {
      circle.classList.add('clicked');
    }
  }
}
