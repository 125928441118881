const routesWithoutLayout: string [] = [
  '/login',
  '/login/request-qr',
  '/users/register',
  '/users/recover-password',
  '/users/reset-password'
];
export class RouteHelper {
  static showLayoutForRoute(url: string) {
    var validation = !routesWithoutLayout.includes(url);
    const regex = /^\/users\/reset-password\b/;
    if (regex.test(url))
      validation = false;
    return validation;
    // return !routesWithoutLayout.includes(url);
  }
}