import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainViewReportComponent } from './components/main-view-report/main-view-report.component';
import { AuthenticatedUserGuard } from '../guards/authenticated-user.guard';
import { ValidateRoutePermissions } from '../auth/models/route-permission.auth';

const routes: Routes = [
  {
    path: 'report',
    children: [
      { path: 'payment-report', component: MainViewReportComponent, canActivate: [AuthenticatedUserGuard], data: {permission: 'list_all_users'} }
    ]
  }
];
ValidateRoutePermissions.registerRoutesAndPermissions(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule {}
