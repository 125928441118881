<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>

    <title>Document</title>
</head>
<body>
    <div class="main">
        <div class="row">
            <!-- Header -->
            <div class="row headerListBook">
                <div class="col-4 p-2">
                    <b class="titleListBook">{{'my_books.all_prodcts_mb' | translate}}</b>
                </div>
                <div class="col-8"></div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <p class="descriptionViewListBook">
                                Explicación de como agregar un Libro Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición 
                                tipográfica.Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.Lorem Ipsum es 
                                simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource">
                          
                              <!-- Position Column -->
                              <ng-container matColumnDef="imgBook">
                                <th mat-header-cell *matHeaderCellDef class="sizeThead text-center"><b>{{'my_books.image_mb' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="imgColSize"><img class="imgBook" src="../../../../assets/img/jpeg/{{element.imgBook}}" alt=""> </td>
                              </ng-container>
                          
                              <!-- Name Column -->
                              <ng-container matColumnDef="codeBook">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.book_code_mb' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.codeBook}}</b></td>
                              </ng-container>
                          
                              <!-- Weight Column -->
                              <ng-container matColumnDef="ISBN">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>ISBN </b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.ISBN}}</b></td>
                              </ng-container>
                          
                              <!-- Symbol Column -->
                              <ng-container matColumnDef="shortDescription">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.short_description_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="shortcolSize"><p class="textDecriptionSize">{{element.shortDescription}}</p></td>
                              </ng-container>

                              <ng-container matColumnDef="resume">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.summary_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="resumecolSize"><p class="textDecriptionSize">{{element.resume}}</p></td>
                              </ng-container>

                              <ng-container matColumnDef="multilanguageText">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.multi_language_text_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.multilanguageText}}</b></td>
                              </ng-container>

                              <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.price_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">${{element.value}}</b></td>
                              </ng-container>

                              <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.actions_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize">  
                                    <a href="#"><i class="bi bi-pencil-fill colorIconAction"></i></a>
                                    <a href="#"><i class="bi bi-eye-fill colorIconAction"></i></a>
                                </td>
                              </ng-container>
                          
                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                              
                            </table>
                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>


                            <!--TO DO: pagination bootstrap 
                                
                                <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                  <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                      <span aria-hidden="true">&laquo;</span>
                                    </a>
                                  </li>
                                  <li class="page-item active"><a class="page-link" href="/book/view-archive-books">1</a></li>
                                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                                  <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Next">
                                      <span aria-hidden="true">&raquo;</span>
                                    </a>
                                  </li>
                                </ul>
                            </nav>    -->
                       </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</body>
</html>

