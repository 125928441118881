import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { UserService } from 'src/app/users/services/user.service';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss']
})
export class ShippingFormComponent implements OnInit, AfterViewInit {
  shippingForm: FormGroup;
  billingForm: FormGroup;
  isBillingFormDisabled = true;

  @ViewChild(PaymentMethodsComponent) paymentMethodsComponent!: PaymentMethodsComponent;

  constructor(
    private fb: FormBuilder,
    private _loginService: LoginService,
    private _userService: UserService,

  ) { }

  ngOnInit(): void {
    this.shippingForm = this.fb.group({
      personType: ['', Validators.required],
      fullName: ['', Validators.required],
      idNumber: ['', Validators.required],
      country: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.billingForm = this.fb.group({
      billingPersonType: [{ value: '', disabled: true }],
      billingFullName: [{ value: '', disabled: true }],
      billingIdNumber: [{ value: '', disabled: true }],
      billingCountry: [{ value: '', disabled: true }],
      billingAddress: [{ value: '', disabled: true }],
      billingEmail: [{ value: '', disabled: true }]
    });

    this.shippingForm.valueChanges.subscribe(() => {
      this.toggleBillingFields();
    });
  }

  ngAfterViewInit() {
    if (this.paymentMethodsComponent) {
      this.paymentMethodsComponent.paymentProcessed.subscribe(() => {
        this.onPaymentProcessed();
      });
    }
  }

  copyPersonalData(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      const personType = this.shippingForm.get('personType')?.value || '';
      const fullName = this.shippingForm.get('fullName')?.value || '';
      const idNumber = this.shippingForm.get('idNumber')?.value || '';
      const country = this.shippingForm.get('country')?.value || '';
      const address = this.shippingForm.get('address')?.value || '';
      const email = this.shippingForm.get('email')?.value || '';

      this.billingForm.patchValue({
        billingPersonType: personType,
        billingFullName: fullName,
        billingIdNumber: idNumber,
        billingCountry: country,
        billingAddress: address,
        billingEmail: email
      });
    } else {
      this.billingForm.reset();
    }
  }

  toggleBillingFields(): void {
    const allPersonalFieldsValid = Object.values(this.shippingForm.controls).every(control => control.valid);
    this.isBillingFormDisabled = !allPersonalFieldsValid;
    if (allPersonalFieldsValid) {
      this.billingForm.enable();
    } else {
      this.billingForm.disable();
    }
  }

  onPaymentProcessed() {
    console.log('Payment processed. Submitting shipping and billing information.');
    this.submitShippingInformation();
  }

  submitShippingInformation() {
    const shippingData = this.shippingForm.value;
    const billingData = this.billingForm.value;

    //set forms data in sessionstorage
    sessionStorage.setItem('shippingData', JSON.stringify(shippingData));
    sessionStorage.setItem('billingData', JSON.stringify(billingData));

    const combinedData = {
      shipping: shippingData,
      billing: billingData
    };

    // Implementa la lógica para enviar la información del formulario
    console.log('Submitting shipping and billing information to the server.');

    this._userService.userShippingInfo(combinedData).subscribe((response: any) => {
      console.log('Data submitted successfully:', response);
    }, (error: any) => {
      console.error('Error submitting data:', error);
    });
  }
}
