import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RequestQrCodeComponent } from './components/request-qr-code/request-qr-code.component';
import { TranslateModule } from '@ngx-translate/core';
import { RecoverPasswordFormComponent } from './components/recover-password-form/recover-password-form.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';



@NgModule({
  declarations: [LoginFormComponent, RequestQrCodeComponent, RecoverPasswordFormComponent, ResetPasswordFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    TranslateModule,
    AuthRoutingModule
  ]
})
export class AuthModule { }
