<div class="main mainResponsive">
  <!-- slider for landscape -->
<div *ngIf="images.length > 0" class="carousel slide" id="carouselI3netControls" data-ride="carousel" data-interval="3500">
  <div class="carousel">
    <div class="carousel-inner">
      <!-- Loop para las imágenes del array -->
      <div class="carousel-item" *ngFor="let image of images; let i = index" [ngClass]="{'active': i === 0}">
        <img [src]="image" class="d-block w-100" alt="Slide">
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselI3netControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <!-- <span class="sr-only">{{'paginator.previous_page' | translate}}</span> -->
  </a>
  <a class="carousel-control-next" href="#carouselI3netControls" role="button" data-slide="next">
    <!-- <span class="sr-only">{{'paginator.next_page' | translate}}</span> -->
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
  </a>
</div>
  <div class="row sizeRow">
    <div class="col-2 filters">
      <div class="col p-2 ">
        <p class="filterTitle">{{'store.filter_search' | translate}}</p>
        <p class="filterSubtitle">{{'store.category' | translate}}</p>
        <div class="col genderfilter">
          <div class="col genderfilter" *ngFor="let categories of CategoriesData">
            <p class="filterBook" *ngIf="categories.isDelete == 0" [ngClass]="{'filtro-seleccionado': selectedCategoryFilter === categories.additionalData['en'].categoryName}" (click)="filterByText(categories.additionalData['en'].categoryName.toLowerCase())">{{categories.additionalData[currentLang].categoryName.toLowerCase()}}</p>
            <!-- <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedCategoryFilter === 'spanish'}" (click)="filterByText('spanish')">{{'store.spanish' | translate}}</p> -->
            <!-- <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedCategoryFilter === 'english'}" (click)="filterByText('english')">{{'store.english' | translate}}</p>
            <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedCategoryFilter === 'japanese'}" (click)="filterByText('japanese')">{{'store.japanese' | translate}}</p>
            <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedCategoryFilter === 'chinese'}" (click)="filterByText('chinese')">{{'store.chinese' | translate}}</p> -->
          </div>
            <hr class="line">
        </div>
        <p class="filterSubtitle">{{'store.age' | translate}}</p>
        <div class="col agefilter">
          <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedRangeFilter === '0 - 3'}" (click)="filterByAgeRange('0 - 3')">0 - 3</p>
          <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedRangeFilter === '4 - 6'}" (click)="filterByAgeRange('4 - 6')">4 - 6</p>
          <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedRangeFilter === '7 - 9'}" (click)="filterByAgeRange('7 - 9')">7 - 9</p>
          <p class="filterBook" [ngClass]="{'filtro-seleccionado': selectedRangeFilter === '10 - 12'}" (click)="filterByAgeRange('10 - 12')">10 - 12</p>
        </div>
        <button (click)="deleteFilter()" class="deleteFilter">{{'store.clean_filter' | translate}}</button>
      </div>
    </div>
    <div class="col-lg-10 col-12" *ngIf="existProducts == true; else noFoundPorducts" style="margin-top: 30px;">
      <div class="row d-flex justify-content-evenly align-items-center" style="gap: 25px;">
        <div class="col-lg-3 col-md-5 col-12 containerBook"
          *ngFor="let product of productsData | paginate: {itemsPerPage: 16 , currentPage: page}" >
          <img [src]="getCoverImageUrl(product.productCoverImageName)" class="imgBook" alt="{{product.productCoverImageName}}">
          <div class="book-responsive-content">
            <!-- TODO -> implement labels on this commented line -->
            <!-- <p class="genderText">Alta fantasía</p> -->
            <p class="bookName">{{getBookTitleByLanguage(product.productId)}}</p>
            <p class="bookDescription">
              {{getBookResumeByLanguage(product.productId)}}
            </p>
            <div class="col">
              <div class="col-3" *ngIf="product.price.currencySymbol != '€'">
                <b class="bookCoin">{{product.price.currencySymbol}}</b><b class="bookPrice">{{getSumPriceTotal(product.price.amount, product.price.taxes) | number:'1.2-4':'es-CO'}}</b>
              </div>
              <div class="col-3" *ngIf="product.price.currencySymbol == '€'">
                <b class="bookPrice">{{getSumPriceTotal(product.price.amount, product.price.taxes) | number:'1.2-4':'es-CO'}}</b><b class="bookCoin">{{product.price.currencySymbol}}</b>
              </div>
              <div class="col-12">
                <button (click)="addToCart(product.productId)" class="addCart2">
                  <i class="bi bi-bag-fill"></i>{{ getTranslatedText('store.add_basket') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-10 col-12 text-center filter-empty" *ngIf="existFilter">
        <div class="row d-flex justify-content-evenly align-items-center">
          <h1>{{'store.filter_empty_sc' | translate}}</h1>
        </div>
      </div>
      <div class="paginationId justify-content-center" *ngIf="!existFilter">
        <pagination-controls (pageChange)="page = $event" previousLabel="" nextLabel=""
          screenReaderPaginationLabel="Pagination" screenReaderCurrentLabel="You're on page">></pagination-controls>
      </div>
    </div>
    <ng-template #noFoundPorducts>
      <div class="col-lg-10 col-12 text-center">
        <div class="row d-flex justify-content-evenly align-items-center">
          <h1>{{'store.updating_collection_books' | translate}}</h1>
          <small>{{'store.not_found_books' | translate}}</small>
        </div>
      </div>
    </ng-template>
  </div>
</div>