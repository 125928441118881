<div class="row h-100 justify-content-center align-items-center">
  <div class="d-flex justify-content-center align-items-center flex-column">
    <ng-container *ngIf="processingPayment">
      <span class="loader"></span>
      <h3>
        {{'payment.processing' | translate}}
      </h3>
    </ng-container>
    <ng-container *ngIf="paymentStatus == 'Payed' && !processingPayment">
      <div class="wrapper">
        <svg class="checkmark_blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle__blue" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
      </div>
      <h3>
        {{'payment.successPaymentTitle' | translate}}
      </h3>
      <p>
        {{'payment.successPaymentMessage' | translate}}
      </p>
      <br><br>
      <button (click)="closeTab()" class="sendButton">{{'payment.returnButton' | translate}}</button>
    </ng-container>
    <ng-container *ngIf="paymentStatus == 'Not payed' && !processingPayment">
      <div class="wrapper">
        <svg class="checkmark_yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle__yellow" cx="26" cy="26" r="25" fill="none"/><path class="checkmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"/></svg>
      </div>
      <h3>
        {{'payment.yetProcessingPaymentTitle' | translate}}
      </h3>
      <p>
        {{'payment.yetProcessingPaymentMessage' | translate}}
      </p>
      <br><br>
      <button (click)="closeTab()" class="sendButton">{{'payment.returnButton' | translate}}</button>
    </ng-container>
    <ng-container *ngIf="paymentStatus == 'Expired' && !processingPayment">
      <div class="wrapper">
        <svg class="checkmark_yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle__yellow" cx="26" cy="26" r="25" fill="none"/><path class="checkmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"/></svg>
      </div>
      <h3>
        {{'payment.errorPaymentTitle' | translate}}
      </h3>
      <p>
        {{'payment.errorPaymentMessage' | translate}}
      </p>
      <br><br>
      <button (click)="closeTab()" class="sendButton">{{'payment.returnButton' | translate}}</button>
    </ng-container>
    <ng-container *ngIf="paymentStatus == 'Rejected' && !processingPayment">
      <div class="wrapper">
        <svg class="checkmark_yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle__yellow" cx="26" cy="26" r="25" fill="none"/><path class="checkmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"/></svg>
      </div>
      <h3>
        {{'payment.rejectedPaymentTitle' | translate}}
      </h3>
      <p>
        {{'payment.rejectedPaymentMessage' | translate}}
      </p>
      <br><br>
      <button (click)="closeTab()" class="sendButton">{{'payment.returnButton' | translate}}</button>
    </ng-container>
  </div>
</div>
