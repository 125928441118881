<i class="bi bi-person-plus-fill colorIconAction"></i><b class="modalTitleModify">{{'users.edit_user' | translate}}</b>
<hr />
<div mat-dialog-content>
  <form [formGroup]="editUserForm">
    <div class="row">
      <div class="col-6">
        <input class="inputSize customInput" type="text" name="name" id="name" formControlName="name"
          placeholder="{{'auth.register.name' | translate}}">
      </div>
      <div class="col-6">
        <input class="inputSize customInput" type="text" name="email" id="email" formControlName="email"
          placeholder="{{'auth.register.email' | translate}}">
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <input class="inputSize customInput" type="password" name="password" formControlName="password" id="password"
          placeholder="{{'auth.register.password' | translate}}">
      </div>
      <div class="col-6">
        <select class="inputSize customInput" id="floatingSelect" formControlName="countryCode">
          <option *ngFor="let country of countries" [value]="country.countryCode">{{getCountryNameByLanguage(country)}}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <select class="inputSize customInput" id="floatingSelect" formControlName="defaultLanguageCode">
          <option *ngFor="let language of languages" [value]="language.languageCode">{{language.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field id="customDropdown" class="inputChiplist example-chip-list" appearance="fill">
          <mat-label>{{'users.add_products' | translate}}</mat-label>
          <mat-chip-list #chipList aria-label="Seleccione un producto">
            <!-- muestra los productos que han sido seleccionados en el selector -->
            <mat-chip *ngFor="let p of productsSelected" (removed)="remove(p)">
              {{getProductNameByLanguage(p)}}
              <span class="divisorInfoProduct"> / </span>
              <button class="buttonQuantityProduct" (click)="changeQuantity(p, 'lower')"
                [disabled]="p.quantity <=1">-</button>
              <span class="quantityProduct">{{ p.quantity }}</span>
              <button class="buttonQuantityProduct" (click)="changeQuantity(p, 'upper')"
                [disabled]="p.quantity >= p.availability">+</button>
              <div class="textQuantityProduct">{{ 'create_product.quantity' | translate | slice:0:4 }}.</div>
              <button matChipRemove>
                <mat-icon><i class="bi bi-x"></i></mat-icon>
              </button>
            </mat-chip>
            <input placeholder="{{'users.add_product' | translate}}" #productInput [formControl]="productCtrl"
              [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
            <!-- (matChipInputTokenEnd)="add($event)" -->
          </mat-chip-list>
          <!-- se cargan los productos al selector-->
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let product of filteredProducts | async" [value]="product"
              [disabled]="product.availability <=0">
              {{getProductNameByLanguage(product)}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="loginButtons">
      <button class="sendButton" type="submit" (click)="editUserFromAdmin()">{{'create_product.save_crp' |
        translate}}</button>
      <button class="closeButton" type="button" [mat-dialog-close]="false">{{'users.cancel' | translate}}</button>
    </div>
  </form>
</div>