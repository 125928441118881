import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PaypalConfirmationComponent } from './components/paypal-confirmation/paypal-confirmation.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../shared/matherial/matherial.module';
import { PayuConfirmationComponent } from './components/payu-confirmation/payu-confirmation.component';
import { ShippingFormComponent } from './components/shipping-form/shipping-form.component';

@NgModule({
  declarations: [
    PaymentMethodsComponent,
    PaypalConfirmationComponent,
    PayuConfirmationComponent,
    ShippingFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaymentRoutingModule,
    TranslateModule,
    MaterialModule
  ],
  exports: [
    PaymentMethodsComponent
  ]
})
export class PaymentModule { }
