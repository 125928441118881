import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { CookieTokenService } from '../auth/services/cookie-token.service';

@Injectable()
export class CustomHttpRequestInterceptor implements HttpInterceptor {

  constructor(private _cookieTokenService: CookieTokenService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let isAuthRequest = false;
    let newRequest = request;
    let urlComponents = request.url.split('/');
    let lastUrlComponent = urlComponents[urlComponents.length - 1];

    env.no_auth_required_routes.forEach((route) => {
      if(lastUrlComponent.includes(route))
        isAuthRequest = true;
    });

    if(!isAuthRequest && this._cookieTokenService.isValidToken()) {
      let headersWithAuth = new HttpHeaders()
      .set('Authorization', `${env.token_type}${this._cookieTokenService.getCookieToken()}`);

      request.headers.keys().forEach((key) => {
        const value = request.headers.get(key) || '';
        headersWithAuth = headersWithAuth.append(key, value);
      });

      newRequest = request.clone({headers: headersWithAuth});
    }

    /*
      The header HAS-NO-CONTENT-TYPE is validated because when we send
      form data, the conten-type header should be removed from headers. So,
      through this header we can detect that a request no need Content-Type header.
    */
    if(!newRequest.headers.has('NO-CONTENT-TYPE')) {
      //For get json responses in requests we add this headers
      newRequest = newRequest.clone({
        headers: newRequest.headers
          .append('Content-type', 'application/json')
          .append('Accept', 'application/json')
      });
    }
    else {
      newRequest = newRequest.clone({
        headers: newRequest.headers
          .delete('NO-CONTENT-TYPE')
          .append('Accept', 'application/json')
      });
    }

    return next.handle(newRequest);
  }
}
