import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { PaymentDetails } from '../../models/payment.model';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ListOrderBooksComponent } from 'src/app/payment/components/list-order-books/list-order-books.component';

@Component({
  selector: 'app-main-view-report',
  templateUrl: './main-view-report.component.html',
  styleUrls: ['./main-view-report.component.scss']
})
export class MainViewReportComponent implements OnInit {
  displayedColumns: string[] = [
    'paymentDetailsId', 'amount', 'provider', 'status', 'orderDetailsId', 'userEmail', 'created_at', 'paymentProducts'
  ];
  dataSource: MatTableDataSource<PaymentDetails> = new MatTableDataSource<PaymentDetails>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private _reportService: ReportService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._pageLoadingService.showLoadingGif();
    this._reportService.getPaymentHistory().subscribe({
      next: (data: CommonResponse<PaymentDetails[]>) => this.getReportPaymentNext(data),
      error: (error: CommonResponse<any>) => this.getReportPaymentError(error),
      complete: () => this._pageLoadingService.hideLoadingGif()
    });
  }

  getReportPaymentNext(data: CommonResponse<PaymentDetails[]>) {
    this.dataSource.data = data.data || [];
    this.dataSource.data.forEach(payment => {
      payment.created_at = this.formatDate(payment.created_at);
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getReportPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if (error.statusCode === 404) {
      this._snackbarService.openStandardSnackBar('getReportPaymentError404');
    } else {
      this._snackbarService.openStandardSnackBar('getReportPaymentError');
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      hour12: true 
    };
    return date.toLocaleDateString('es-ES', options);
  }

  // show more details modal
  showMoreDetails(paymentId: number) {
    const confirmPurchaseDialogRef = this._dialog.open(ListOrderBooksComponent, {
      height: '90%',
      width: '90%',
      data: { paymentId } // Pasar el ID del pago al componente de diálogo
    });
  }
}

