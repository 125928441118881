import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { CountryService } from 'src/app/countries/services/country.service';
import { MyAccountComponent } from 'src/app/users/components/my-account/my-account.component';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  countryName: string = '';

  constructor(
    private _countryService: CountryService, 
    private cdr: ChangeDetectorRef, 
    private translateService: TranslateService,
    private _dialog: MatDialog,
    private router: Router,
     private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      window.scrollTo(0, 0);
    });
    const storedCountryName = localStorage.getItem('countryName');
    this.changeImagesFooter();
    if (storedCountryName !== null) {
      this.countryName = storedCountryName;
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
      // this.cdr.detectChanges(); // Marcar la vista como actualizada
    } else {
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    }
  }

  ngAfterViewInit(): void {
    // Suscríbete al evento de cambio de idioma
    this.translateService.onLangChange.subscribe(() => {
      this.changeImagesFooter();
    });
  }

  myAccount() {
    const confirmPurchaseDialogRef = this._dialog.open(MyAccountComponent, {
      height: '70%',
      width: '71%',
    });

  }

  changeImagesFooter() {
    const lang = this.translateService.currentLang; // Obtener el idioma actual

    $('.imgMinEur').removeClass('en es');
    $('.imgMinEur').addClass(lang);
  }

  scrollToTopAndNavigate(): void {
    this.el.nativeElement.ownerDocument.defaultView.scrollTo({ top: 0, behavior: 'smooth' });
    this.router.navigate(['/book/shop']);
  }

  getTranslatedText(key: string): string {
    let storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
      return this.translateService.instant(key + '_spain');
    } else {
      return this.translateService.instant(key);
    }
  }

}
