import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { ResponseHelper } from '../../shared/helpers/response.helper';

@Injectable({
  providedIn: 'root'
})
export class LicenseTypeService {

  constructor(private _httpClient: HttpClient)
  {
  }

  getAllServices() {

    return this._httpClient.get(`${env.url_api}/${env.api_version}/licenseType/licenses`, { observe: 'response' as 'response'})
    .pipe(map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }
}
