import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { environment as env } from 'src/environments/environment';
import { LoginService } from '../../services/logIn.service';
import { UserService } from 'src/app/users/services/user.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { log } from 'console';
import { CountryService } from 'src/app/countries/services/country.service';

@Component({
  selector: 'app-recover-password-form',
  templateUrl: './recover-password-form.component.html',
  styleUrls: ['./recover-password-form.component.scss']
})
export class RecoverPasswordFormComponent implements OnInit {

  email: string = "";
  password: string = "";
  showErrorMessages = false;
  countryName: string = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private readonly recoverPasswordObserver = {
    next: (data: CommonResponse<any>) => this.recoverPasswordNext(data),
    error: (error: CommonResponse<any>) => this.recoverPasswordError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(private _loginService: LoginService,
    private _userService: UserService,
    private _snackbarService: SnackbarService,
    private _sanitizer: DomSanitizer,
    private _countryService: CountryService,
    private cdr: ChangeDetectorRef,
    private _pageLoadingService: PageLoadingService) { }

    ngOnInit() {
      const storedCountryName = localStorage.getItem('countryName');
      if (storedCountryName !== null) {
        this.countryName = storedCountryName;
        this._countryService.getCountryName$().subscribe(countryName => {
          this.countryName = countryName;
          this.cdr.detectChanges(); // Marcar la vista como actualizada
        });
      } else {
        this._countryService.getCountryName$().subscribe(countryName => {
          this.countryName = countryName;
          this.cdr.detectChanges(); // Marcar la vista como actualizada
        });
      }
    }

  recoverPasswordNext(data: CommonResponse<any>) {
    if (ResponseHelper.responseDontHaveErrors(data)) {
      console.log('sin errores');
      this._snackbarService.openStandardSnackBar('email_send');
    }else{
      console.log('con errores');
      this._snackbarService.openStandardSnackBar('email_send');
    }

    this._pageLoadingService.hideLoadingGif();
  }

  recoverPasswordError(error: CommonResponse<any>) {
    this._snackbarService.openStandardSnackBar('email_no_found');
    this._pageLoadingService.hideLoadingGif();
  }

  sendEmailRecoverPassword() {
    if (!this.isInvalidEmail()) {
      this._pageLoadingService.showLoadingGif();

      let response: Observable<CommonResponse<any>> = this._userService.sendRecoverPassword(this.email);
      response.subscribe(this.recoverPasswordObserver);
      
    }
    else {
      this.showErrorMessages = true;
    }
  }

  isInvalidEmail() {
    let regExValidator = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
    return !regExValidator.test(this.email);
  }

}
