import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ResponseHelper } from '../../shared/helpers/response.helper';

@Injectable({
  providedIn: 'root'
})
export class EditorialService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getEditorialCountries() {
    var language ="spa";
    const countryNameStorage = sessionStorage.getItem('selectedLanguage');
    
    if (countryNameStorage != 'es') {
      language = "eng";
    }
    //TODO: add user language and user editorialId    
    let params = new HttpParams()
      .set('editorialId', 1)
      .set('language', language);

    return this._httpClient.get(`${env.url_api}/${env.api_version}/editorial/countries`, { observe: 'response' as 'response', params })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  createBook(newBookData: FormData) {

    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content') 
      .set('Accept', 'application/json');
      
    return this._httpClient.post(`${env.url_api}/${env.api_version}/editorial/product`, newBookData, { observe: 'response' as 'response', headers: headers })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  updateProduct(updateProductData: FormData) {

    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.post(`${env.url_api}/${env.api_version}/editorial/products`, updateProductData, { observe: 'response' as 'response', headers: headers })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  updateGeneralInfo(updateGeneralInfo: FormData) {

    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.post(`${env.url_api}/${env.api_version}/editorial/generalInfo`, updateGeneralInfo, { observe: 'response' as 'response', headers: headers })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  getproducts(editorialId: any, paginate: any, idUser: any) {
    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.get(`${env.url_api}/${env.api_version}/editorial/products/${editorialId}/${paginate}/${idUser}`, { observe: 'response' as 'response', headers: headers })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  editProduct(productId: any) {
    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.get(`${env.url_api}/${env.api_version}/editorial/product/edit/${productId}`, { observe: 'response' as 'response', headers: headers })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  deleteProduct(productId: any){
    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.post(`${env.url_api}/${env.api_version}/editorial/delete/${productId}`, productId, { observe: 'response' as 'response', headers: headers })
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  getAllproducts(editorialId: any) {
    let headers = new HttpHeaders()
      .set('NO-CONTENT-TYPE', 'no-content')
      .set('Accept', 'application/json');

    return this._httpClient.get(`${env.url_api}/${env.api_version}/editorial/products/${editorialId}`, { observe: 'response' as 'response', headers: headers })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }
}
