import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoriesService } from 'src/app/users/services/categories.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { categoryId } from 'src/app/books/models/category-product.models';
import { categoriesData } from 'src/app/books/models/category-product.models';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

type CategoryUpdate = {
  userId: number;
  nameData: {
    name: string;
    isdeleted: number;
  };
}


@Component({
  selector: 'app-edit-categories',
  templateUrl: './edit-categories.component.html',
  styleUrls: ['./edit-categories.component.scss']
})
export class EditCategoriesComponent {

  categoriesData: categoriesData;
  categoryUpdate = {} as CategoryUpdate
  categoryId: categoryId;
  status: number;
  editCategoriesForm: FormGroup;


  @ViewChild('productInput') productInput: ElementRef<HTMLInputElement>;

  readonly getCategoriesObserver = {
    next: (data: CommonResponse<any>) => this.getCategoriesNext(data),
    error: (error: CommonResponse<any>) => this.getCategoriesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly updateNewObserver = {
    next: (data: CommonResponse<any>) => this.newCategoryNext(data),
    error: (error: CommonResponse<any>) => this.newCategoryError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };
  
  constructor(
    private fb: FormBuilder, 
    public dialogRef: MatDialogRef<EditCategoriesComponent>,
    private _CategoriesService: CategoriesService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: { userId: number, name: string, status: number },
  ) {
    
    this.categoryUpdate.userId = data.userId;
    this.categoryUpdate.nameData = { name: data.name, isdeleted: data.status };

    this._CategoriesService.getAllCategories(this.data.userId).subscribe(this.getCategoriesObserver);

    this.editCategoriesForm = this.fb.group({
      name: ['', Validators.required],
      status: ['', Validators.required]
    });

  }
  
  getCategoriesNext(data: CommonResponse<any>) {    
    this.categoriesData = data.data[0]
    this.status = this.categoriesData.isDelete
    this.editCategoriesForm.get('name')?.setValue(this.categoriesData.name)
    
  }

  newCategoryNext(data: CommonResponse<any>) {
    if (data.statusCode >= 200 && data.statusCode < 300) {
      window.location.reload();
    }
  }

  getCategoriesError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  newCategoryError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  editCategories() {
    if (this.categoriesData.name !== this.editCategoriesForm.get("name")?.value) {
      this.categoryUpdate.nameData.name = this.editCategoriesForm.get("name")?.value;
    }
    let deleteOption = document.getElementById('floatingSelect') as HTMLSelectElement;
    let deleteOptionNumber = parseInt(deleteOption.value);
    if (this.categoriesData.isDelete !== deleteOptionNumber) {
      this.categoryUpdate.nameData.isdeleted = deleteOptionNumber;
    }
    this._CategoriesService.updateCategory(this.categoryUpdate)
      .subscribe(this.getCategoriesObserver);
      this._CategoriesService.updateCategory(this.categoryUpdate)
      .subscribe(this.updateNewObserver);
    this.dialogRef.close(true);
  }

}
