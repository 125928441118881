<div class="principalContainer" [formGroup]="shippingForm">
  <div class="title">{{'shipping.shipping_information' | translate}}</div>
  <h5 class="subtitle">{{'shipping.personal_information' | translate}}</h5>

  <div class="row">
    <div class="col-6 shippingInput">
      <mat-form-field appearance="fill" class="dropdown">
        <mat-label>{{'shipping.person_type' | translate}}</mat-label>
        <mat-select formControlName="personType">
          <mat-option value="v1">{{'shipping.natural_person' | translate}}</mat-option>
          <mat-option value="v2">{{'shipping.company' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-6 shippingInput">
      <label for="fullName">{{'shipping.full_name' | translate}}</label>
      <input type="text" formControlName="fullName" placeholder="{{'shipping.name' | translate}}" class="inputSize">
    </div>
    <div class="col-6 shippingInput">
      <label for="idNumber">{{'shipping.identity_document' | translate}}</label>
      <input type="number" formControlName="idNumber" placeholder="{{'shipping.document' | translate}}"
        class="inputSize">
    </div>
  </div>

  <div class="row">
    <div class="col-6 shippingInput">
      <label for="country">{{'shipping.country' | translate}}</label>
      <input type="text" formControlName="country" placeholder="{{'shipping.country' | translate}}" class="inputSize">
    </div>
    <div class="col-6 shippingInput">
      <label for="address">{{'shipping.address' | translate}}</label>
      <input type="text" formControlName="address" placeholder="{{'shipping.address' | translate}}" class="inputSize">
    </div>
  </div>

  <div class="row">
    <div class="shippingInput">
      <label for="email">{{'shipping.email' | translate}}</label>
      <input type="email" formControlName="email" placeholder="{{'shipping.email' | translate}}" class="inputSize">
    </div>
  </div>
</div>

<hr>

<div class="principalContainer" [formGroup]="billingForm"> 
  <h5 class="subtitle">{{'shipping.billing_information' | translate}}</h5>

  <div class="row">
    <div id="checkContainer">
      <input type="checkbox" id="copyCheckbox" (change)="copyPersonalData($event)" [disabled]="shippingForm.invalid"> 
      <label for="copyCheckbox">{{'shipping.fill_in_information_with_personal_data' | translate}}</label>
    </div>
  </div>

  <div class="row">
    <div class="col-6 shippingInput">
      <mat-form-field [ngClass]="{'disabled-input': isBillingFormDisabled}" appearance="fill" class="dropdown end">
        <mat-label>{{'shipping.person_type' | translate}}</mat-label>
        <mat-select formControlName="billingPersonType">
          <mat-option value="v1">{{'shipping.natural_person' | translate}}</mat-option>
          <mat-option value="v2">{{'shipping.company' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-6 shippingInput">
      <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingFullName">{{'shipping.full_name' |
        translate}}</label>
      <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="text" formControlName="billingFullName"
        placeholder="{{'shipping.name' | translate}}" class="inputSize">
    </div>
    <div class="col-6 shippingInput">
      <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingIdNumber">{{'shipping.identity_document'
        |
        translate}}</label>
      <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="number" formControlName="billingIdNumber"
        placeholder="{{'shipping.document' | translate}}" class="inputSize">
    </div>
  </div>

  <div class="row">
    <div class="col-6 shippingInput">
      <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingCountry">{{'shipping.country' |
        translate}}</label>
      <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="text" formControlName="billingCountry"
        placeholder="{{'shipping.country' | translate}}" class="inputSize">
    </div>
    <div class="col-6 shippingInput">
      <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingAddress">{{'shipping.address' |
        translate}}</label>
      <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="text" formControlName="billingAddress"
        placeholder="{{'shipping.address' | translate}}" class="inputSize">
    </div>
  </div>

  <div class="row" id="lastInput">
    <div class="shippingInput">
      <label [ngClass]="{'disabled-label': isBillingFormDisabled}" for="billingEmail">{{'shipping.email' |
        translate}}</label>
      <input [ngClass]="{'disabled-input': isBillingFormDisabled}" type="email" formControlName="billingEmail"
        placeholder="{{'shipping.email' | translate}}" class="inputSize">
    </div>
  </div>
  <div>
    <h5 class="subtitle pay" for="">{{'shipping.how_do_you_want_to_pay' | translate}}</h5>
    <ng-container>
      <app-payment-methods></app-payment-methods>
    </ng-container>
  </div>
</div>