<div class="principalContainer">
    <h2 class="text-center">{{ 'payment-report.productsTitle' | translate }}</h2>
    <div class="container mt-5 d-flex flex-column align-items-center">
      <button class="btn mb-3 btnClose" (click)="closeReport()">X</button>
      <div>
        <table class="styled-table">
          <thead>
            <tr>
              <th>{{ 'payment-report.productName' | translate }}</th>
              <th>{{ 'payment-report.productCode' | translate }}</th>
              <th>{{ 'payment-report.productPrice' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of productsList">
              <td>{{ product.title }}</td>
              <td>{{ product.interactiveCode }}</td>
              <td>{{ product.amount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  