<div class="titileContainer">
  <div class="title" >{{'Questions.title_question' | translate}}</div>
</div>
<div class="infoText">
  {{'Questions.question_text' | translate}}
</div>
<div class="container mt-5">
  <div *ngFor="let question of questions; let i = index" class="accordion">
    <div class="accordion-item">
      <h2 class="accordion-header" [id]="'heading' + i">
        <button class="accordion-button collapsed" type="button" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
          {{ question.question }}
        </button>
      </h2>
      <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent=".accordion">
        <div class="accordion-body">
          {{ question.answer }}
        </div>
      </div>
    </div>
  </div>
</div>
  