<div class="title">{{'Questions.questions_admin' | translate}}</div>
<div class="questionContainer">
  <div class="questionsLabels">
    <div class="textContainer">
      <label for="pregunta">{{'Questions.question' | translate}}</label>
      <textarea id="pregunta" #preguntaTextarea (input)="onQuestionInput($event)"
        [placeholder]="'Questions.question_holder' | translate"></textarea>
    </div>
    <div class="textContainer">
      <label for="respuesta">{{'Questions.answer' | translate}}</label>
      <textarea id="respuesta" #respuestaTextarea (input)="onAnswerInput($event)"
        [placeholder]="'Questions.answer_holder' | translate"></textarea>
    </div>
    <div class="selectContainer">
      <label for="licence">{{'Files.language' | translate}}</label>
      <mat-form-field appearance="fill" class="dropdown end">
        <mat-label>{{'Files.language' | translate}}</mat-label>
        <mat-select (selectionChange)="onLanguageChange($event)">
          <mat-option value="es">{{'Files.spanish' | translate}}</mat-option>
          <mat-option value="en">{{'Files.english' | translate}}</mat-option>
          <mat-option value="pt">{{'Files.Portuguese' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="buttonsContainer">
    <button (click)="submit()">{{'Questions.send' | translate}}</button>
  </div>
  <div class="tableContainer">
    <table class="table table-striped col-1">
      <thead>
        <tr>
          <th> {{'Questions.question' | translate}} </th>
          <th> {{'Questions.answer' | translate}} </th>
          <th> {{'Questions.language' | translate}} </th>
          <th> {{'Questions.actions' | translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let question of questions">
          <td>{{question.question}}</td>
          <td>{{question.answer}}</td>
          <td>{{question.language}}</td> <!-- Muestra el idioma -->
          <td>
            <i (click)="deleteQuestion(question.id)" data-toggle="modal" data-target="#modalConfirmation"
              class="bi bi-trash-fill"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
