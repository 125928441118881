<div class="supportContainer">
  <div class="title">{{'Files.support' | translate}}</div>
  <div class="infoText">
    {{'Files.file_text' | translate}}
  </div>
  <div class="container mt-5">
    <table>
      <tbody>
        <tr *ngFor="let fileName of uniqueFileNames">
          <td id="name">{{ fileName }}</td>
          <td>
            <select (change)="onVersionSelect($event, fileName)">
              <option *ngFor="let version of getVersions(fileName)" [value]="version"
                [selected]="isSelectedVersion(fileName, version)">
                {{ version }}
              </option>
            </select>
          </td>
          <td>
            <select (change)="onLanguageSelect($event, fileName)">
              <option *ngFor="let language of getLanguages(fileName)" [value]="language">
                {{ language }}
              </option>
            </select>
          </td>
          <td>
            <div class="download">
              <a id="downloadLink" *ngIf="hasSelectedVersion(fileName)"[href]="getDownloadUrl(fileName, selectedVersion[fileName], selectedLanguage[fileName])" download="{{fileName}}">
                <i class="bi bi-download"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
