<div id="loadingView">
  <div class="loadingBackground"></div>
  <div class="loader">Loading...</div>
</div>
<ng-container *ngIf="showLayout; else elseTemplate">
  <div class="h-100 row pageBody">
    <div class="col-12">
      <div class="row">
        <div class="col">
          <app-navbar></app-navbar>
        </div>
      </div>
      <div class="row spaced-content spaced-content-modify">
        <div id="componentContent">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="row spaced-content">
        <app-footer></app-footer>
      </div>
    </div>
    <app-banner-cookies></app-banner-cookies>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <router-outlet></router-outlet>
  <app-banner-cookies></app-banner-cookies>
</ng-template>
