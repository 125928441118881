<body>
  <div class="container row">
    <div class="containerLeft col-10 col-md-6 justify-content-between">
      <div id="loginContainer">
        <div class="logo">
          <ng-container *ngIf=" countryName === ''">
            <img src="" class="mb-3 col-9" style="margin-top: 12rem !important;">
          </ng-container>
          <ng-container *ngIf=" countryName !== 'Colombia' && countryName !== ''">
            <img src="../../../../assets/img/png/gridmark-login.png" class="gridmark">
            <img src="../../../../assets/img/png/watermark_gridmark.png" class="watermarkGridmark">
            <img src="../../../../assets/img/png/watermark_gridmark.png" class="watermarki3book2">
          </ng-container>
          <ng-container *ngIf=" countryName === 'Colombia'">
            <img src="../../../../assets/img/png/logo-white.png" class="i3book">
            <img src="../../../../assets/img/png/watermark_i3book.png" class="watermarki3book">
            <img src="../../../../assets/img/png/watermark_i3book.png" class="watermarki3book2">
          </ng-container>
        </div>
        <div class="text-image">
          <p class="imageCaption">
            {{'auth.login.intro_text' | translate}}
          </p>
        </div>
      </div>
    </div>
    <div class="containerRight col-10 col-md-6 background-login-form py-5 bg-white">
      <div class="login">
        <p class="text-white titleLogin">{{'auth.recover_password.title' | translate}}</p>
        <div class="subtitle d-flex justify-content-center">
          <p class="fw-lighter text-white col-10">{{'auth.recover_password.text' | translate}}</p>
        </div>
        <form action="">
          <div class="inputLogin pr-2 pb-2">
            <input class="inputslogin" [(ngModel)]="email" type="email" name="email" id="email"
              placeholder="{{'auth.recover_password.email_lbl' | translate}}">
            <div *ngIf="isInvalidEmail() && showErrorMessages" class="negative-feedback">
              <p>{{'auth.login.user_name_validation' | translate}}.</p>
            </div>

          </div>
          <div class="hintText">
            <p class="">
              <a [routerLink]="['/users/register']" class="text-reset">{{'auth.login.get_register_question' |
                translate}}</a>
            </p>
            <p class="">
              <a [routerLink]="['/login']" class="text-reset">{{'auth.register.have_aready_account' | translate}}</a>
            </p>
          </div>
          <div class="loginButtons" style="margin-top: 0px;">
            <button class="sendButton" type="submit" (click)="sendEmailRecoverPassword()">{{'auth.login.send' |
              translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</body>
