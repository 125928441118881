import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { CommonResponse } from "../models/reponse.model";

export class ResponseHelper {
  static responseDontHaveErrors(response: CommonResponse<any>) {
    return response.data &&
      !('error' in response.data) &&
      (response.errors === null || response.errors === undefined);
  }

  static generateCommonResponse(response: HttpResponse<any>) {
    let commonResponse = new CommonResponse();

    commonResponse.data = response.body?.data || undefined;
    commonResponse.message = response.body.message;
    commonResponse.errors = response.body.errors || undefined;
    commonResponse.statusCode = response.status;

    return commonResponse;
  }

  static generateCommonResponseFromHttpErrorResponse(response: HttpErrorResponse) {
    let commonResponse = new CommonResponse();

    commonResponse.message = response.error.message;
    commonResponse.errors = response.error.errors || undefined;
    commonResponse.statusCode = response.status;

    return commonResponse;
  }
}
