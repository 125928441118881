import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { EditBookInformationComponent } from './components/edit-book-information/edit-book-information.component';
import { ListShopBooksComponent } from './components/list-shop-book/list-shop-books.component';
import { ViewArchivedBooksTableInformationComponent } from './components/view-archived-books-table-information/view-archived-books-table-information.component';
import { AuthenticatedUserGuard } from '../guards/authenticated-user.guard';
import { BookDetailsInformationComponent } from './components/book-details-information/book-details-information.component';
import { ViewMyBooksComponentComponent } from './components/view-my-books-component/view-my-books-component.component';
import { ValidateRoutePermissions } from '../auth/models/route-permission.auth';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { ShopCartComponent } from './components/shop-cart/shop-cart.component';
import { ListCategoryComponent } from './components/list-category/list-category.component';

const routes: Routes = [
  { path: 'book',
    children: [
      {path: 'shop', component: ListShopBooksComponent},
      {path: 'shop-cart', component: ShopCartComponent},
      {path: 'detail-information', component: BookDetailsInformationComponent},
      {path: 'edit-product/:id', component: EditProductComponent, data: {permission: 'update_book'}},
      {path: 'view-archived-books', component: ViewArchivedBooksTableInformationComponent, canActivate: [AuthenticatedUserGuard], data: {permission: 'list_book'}},
      {path: 'create', component: EditBookInformationComponent, canActivate: [AuthenticatedUserGuard], data: {permission: 'create_book'}},
      {path: 'my-books', component: ViewMyBooksComponentComponent, canActivate: [AuthenticatedUserGuard], data: {permission: 'list_book'}},
      {path: 'categories', component: ListCategoryComponent, canActivate: [AuthenticatedUserGuard], data: {permission: 'create_book'}},
      {path: '**', redirectTo: 'shop'}
    ]
  }
];

ValidateRoutePermissions.registerRoutesAndPermissions(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BooksRoutingModule {}

