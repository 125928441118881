import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countryName: string;
  countryName$: Subject<string>;

  constructor(private _httpClient: HttpClient) {
    this.countryName$ = new Subject();
    this.countryName = localStorage.getItem('countryName') || '';
    if (this.countryName) {
      this.countryName$.next(this.countryName);
    }
  }

  setCountryName(countryName: string) {
    this.countryName = countryName;
    localStorage.setItem('countryName', countryName);
    this.countryName$.next(this.countryName);
  }

  getCountryName(): string {
    return this.countryName;
  }

  getCountryName$(): Observable<string> {
    return this.countryName$.asObservable();
  }

  getCountryInfoByClientIp(languageCode: string) {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/country-by-ip/${languageCode}`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getAllCountries() {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/countries`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getCountryInfoByUrl(url: string, languageCode: string): Observable<any> {
    return this._httpClient.post<any>(`${env.url_api}/${env.api_version}/get-country-info`, { url, languageCode });
  }
}
