import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import errorMessages from '../../../../json/error-messages.json';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar, private http: HttpClient, private _translateService: TranslateService) {}

  selectedLanguage: string;

  openStandardSnackBar(message: string, action: string = 'x', duration: number = 5000) {
    this.selectedLanguage = this._translateService.currentLang;
    let errorMessage: string = "";

    this.http.get<any>(`assets/i18n/${this.selectedLanguage}.json`).subscribe(
      responseMessages => {
        if (responseMessages.popUpMessages[message]) {
          errorMessage = responseMessages.popUpMessages[message];
        } else {
          errorMessage = responseMessages.popUpMessages.defaultErrorMessage ? responseMessages.popUpMessages.defaultErrorMessage : 'Error desconocido.';
        }
      },
      error => {
        return `Error al cargar el archivo de mensajes de error: ${error}`;
      }
    );

    setTimeout(() => {
      this._snackBar.open(errorMessage, action, {
        duration: duration,
        panelClass: ['snack-bar-panel']
      });
    }, 1000);
  }
}
