import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-banner-cookies',
  templateUrl: './banner-cookies.component.html',
  styleUrls: ['./banner-cookies.component.scss']
})
export class BannerCookiesComponent implements OnInit {

  cookiesAccepted: boolean = false;

  constructor(  private cookieService: CookieService,
    ) {
      this.checkCookiesConsent();
      // this.deleteCookie();
    }

  ngOnInit(): void {
  }

  deleteCookie() {
    this.cookieService.delete('cookies_accepted');
  }

  checkCookiesConsent() {
    const cookiesAccepted = this.cookieService.get('cookies_accepted');
    const allCookies = this.cookieService.getAll();

    if (cookiesAccepted === 'true') {
      this.cookiesAccepted = true;
    }
  }

  acceptCookies() {
    this.cookieService.set('cookies_accepted', 'true');
    this.cookiesAccepted = true;
  }

  denyCookies() {
    this.cookiesAccepted = true;
  }
  
}
