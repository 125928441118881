import { Component, OnInit } from '@angular/core';

import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Books } from '../../models/view-archive-books';

const LIST_BOOKS: Books[] = [
  {
    imgBook: 'principito.jpg',
    codeBook: 389478932,
    ISBN: 'AH094GB',
    shortDescription: 'Libro para niños el principito',
    resume:'El Principito, es una narracion corta del escritor frances Antoine de Saint-Exupéry,trata de la historia de un pequeño principe que parte de su planeta a una travesia por el universo en la cual descubre la extraña forma en que las personas adultas ven la vida y comprende el valor del amor y la amistad',
    multilanguageText:'Espanol Ingles',
    value:30000
  },
  {
    imgBook: 'da_vinci.jpg',
    codeBook: 389478932,
    ISBN: 'AH094GB',
    shortDescription: 'Libro de drama',
    resume:'Al profundizar en la investigación, Langdon descubre que las pistas conducen a las obras de Leonardo Da Vinci... y que están a la vista de todos, ocultas por el ingenio del pintor. Langdon une esfuerzos con la criptóloga francesa Sophie Neveu y descubre que el conservador del museo pertenecía al priorato de Sión, una sociedad que a lo largo de los siglos ha contado con miembros tan destacados como sir Isaac Newton, Botticelli, Víctor Hugo o el propio Da Vinci, y que ha velado por mantener en secreto una sorprendente verdad histórica.',
    multilanguageText:'espanol',
    value:42500
  },
  {
    imgBook: 'viento.jpg',
    codeBook: 389478932,
    ISBN: 'AH094GB',
    shortDescription: 'Libro de amor',
    resume:'Scarlett O Hara vive en Tara, una gran plantación del estado sureño de  Georgia, y está enamorada de Ashley Wilkes, que en breve contraerá  matrimonio con Melanie Hamilton. Estamos en 1861, en los prolegómenos de  la guerra de Secesión, y todos los jóvenes sureños muestran entusiasmo  por entrar en combate, excepto el atractivo aventurero Rhett Butler.',
    multilanguageText:'espanol',
    value:25000
  },
  {
    imgBook: 'vecinos.jpg',
    codeBook: 389478932,
    ISBN: 'AH094GB',
    shortDescription: 'Libro de suspenso',
    resume:'Este relato de suspenso psicológico se desarrolla entre un hombre y su vecina, una viejecita aparentemente dulce. El autor logra crear una trama apasionante desde diferentes voces narrativas. El final marcará una vuelta de tuerca por su inesperada solución.',
    multilanguageText:'espanol',
    value:36000
  },
  {
    imgBook: 'principito.jpg',
    codeBook: 389478932,
    ISBN: 'AH094GB',
    shortDescription: 'Libro para niños el principito',
    resume:'El Principito, es una narracion corta del escritor frances Antoine de Saint-Exupéry,trata de la historia de un pequeño principe que parte de su planeta a una travesia por el universo en la cual descubre la extraña forma en que las personas adultas ven la vida y comprende el valor del amor y la amistad',
    multilanguageText:'Espanol Ingles',
    value:30000
  },
  {
    imgBook: 'vecinos.jpg',
    codeBook: 389478932,
    ISBN: 'AH094GB',
    shortDescription: 'Libro de suspenso',
    resume:'Este relato de suspenso psicológico se desarrolla entre un hombre y su vecina, una viejecita aparentemente dulce. El autor logra crear una trama apasionante desde diferentes voces narrativas. El final marcará una vuelta de tuerca por su inesperada solución.',
    multilanguageText:'espanol',
    value:36000
  },
];

@Component({
  selector: 'app-view-archived-books-table-information',
  templateUrl: './view-archived-books-table-information.component.html',
  styleUrls: ['./view-archived-books-table-information.component.scss']
})
export class ViewArchivedBooksTableInformationComponent implements OnInit {

  displayedColumns: string[] = ['imgBook', 'codeBook', 'ISBN', 'shortDescription', 'resume', 'multilanguageText', 'value', 'actions'];
  dataSource = new MatTableDataSource<Books>(LIST_BOOKS);

  @ViewChild(MatPaginator) paginator: MatPaginator;



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  constructor() { }

  ngOnInit(): void {

  }

}
