import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/services/logIn.service';
import { defaultRoutesForRole, ValidateRoutePermissions } from '../auth/models/route-permission.auth';
import { SnackbarService } from '../shared/services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUserGuard implements  CanActivate {

  constructor(private _loginService: LoginService,
    private _snackbarService: SnackbarService,
    private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return this.validate(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.validate(state.url);
  }

  validate(url: string) {

    if (this._loginService.isLogged()) {

      const user = this._loginService.getLoggedUser();

      if(user) {
        const userRoles = defaultRoutesForRole.filter(r => user.roles.includes(r.role));
        const loggedUserPermissions = user.permissions;
        const isAllowedRouteForUser = ValidateRoutePermissions.validatePermission(url, loggedUserPermissions);

        if(!isAllowedRouteForUser && !userRoles.some(r => r.defaultRoute[0] == url)) {
          this._router.navigate(userRoles[0].defaultRoute);
          return false;
        }
      }
    }
    else {
      this._router.navigate(['/login']);
      if(this._loginService.getLoggedUser() !== null &&
        this._loginService.getLoggedUser() !== undefined) {
        }
      return false;
    }

    return true;
  }

}





