<div class="main">
    <div class="row">
        <!-- Header -->
        <div class="row headerListBook">
            <div class="col-8 p-2">
                <b class="titleListBook"> {{'footer.terms_conditions_ft' | translate}} </b>
            </div>
            <div class="col-7"></div>
            <br>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="textDescriptionContainer">
                            <div class="LinksDiv">
                                <div class="line"></div>
                                <div class="list" (click)="scrollToTitle ('information'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.information' | translate}}
                                </div>

                                <div class="list" (click)="scrollToTitle ('license'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.licesnse' | translate}}
                                </div>

                                <div class="list"
                                    (click)="scrollToTitle ('unauthorizedUse'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.unauthorized_use' | translate}}
                                </div>

                                <div class="list" (click)="scrollToTitle ('como'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.como' | translate}}
                                </div>

                                <div class="list" (click)="scrollToTitle ('impuestos'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.impuestos' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('obligaciones'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.obligaciones' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('pago'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.pago' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('envio'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.envio' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('gastos'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.gastos' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('derechos'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.derechos' | translate}}
                                </div>
                                <div class="list" (click)="scrollToTitle ('legislacion'); toggleCircleClass($event)">
                                    <div class="circle"></div>
                                    {{'Terms.legislacion' | translate}}
                                </div>
                            </div>
                            <div class="descriptionViewListBook">
                                <div class="textDecriptionSize">
                                    <strong id="information">{{'Terms.information' | translate}}</strong>
                                    <br><br>
                                    <div [innerHTML]="'Terms.information_text' | translate"></div>
                                    <br><br>
                            
                                    <strong id="license">{{'Terms.licesnse' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.licesnse_text' | translate}}
                                    <br><br>
                            
                                    <strong id="unauthorizedUse">{{'Terms.unauthorized_use' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.unauthorized_use_text' | translate}}
                                    <br><br>
                            
                                    <strong id="como">{{'Terms.como' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.como_text' | translate}}
                                    <br><br>
                            
                                    <strong id="impuestos">{{'Terms.impuestos' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.impuestos_text' | translate}}
                                    <br><br>
                            
                                    <strong id="obligaciones">{{'Terms.obligaciones' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.obligaciones_text' | translate}}
                                    <br><br>
                            
                                    <strong id="pago">{{'Terms.pago' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.pago_text' | translate}}
                                    <br><br>
                            
                                    <strong id="envio">{{'Terms.envio' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.envio_text' | translate}}
                                    <br><br>
                            
                                    <strong id="gastos">{{'Terms.gastos' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.gastos_text' | translate}}
                                    <br><br>
                            
                                    <strong id="derechos">{{'Terms.derechos' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.derechos_text' | translate}}
                                    <br><br>
                            
                                    <strong id="legislacion">{{'Terms.legislacion' | translate}}</strong>
                                    <br><br>
                                    <div [innerHTML]="'Terms.legislacion_text' | translate"></div>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="mat-elevation-z8">
                        <ng-container matColumnDef="resume">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <b>{{'create_product.summary_crp' | translate}}</b>
                            </th>
                            <td mat-cell *matCellDef="let element" class="resumecolSize">
                            </td>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>