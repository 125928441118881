import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieTokenService {
  private readonly COOKIE_NAME: string = "i3net_vint_token";

  constructor(private _cookieService: CookieService){
  }

  getCookieToken() {
    return this._cookieService.get(this.COOKIE_NAME);
  }

  setCookieToken(tokenValue: string) {
    this._cookieService.set(this.COOKIE_NAME, tokenValue);
  }

  deleteCookieToken() {
    this._cookieService.delete(this.COOKIE_NAME);
  }

  isValidToken() {
    let cookieToken = this.getCookieToken();

    return typeof cookieToken != 'undefined' && cookieToken ? true : false;
  }
}
