<div class="row">
  <div class="text-center image" style="margin-bottom:15px">
    <img [src]="getCoverImageUrl(cartProduct.product.productCoverImageName)" alt="" class="imgBook">
  </div>
  <div class="info">
    <div class="row dataBook">
      <div class="col-8 ">
        <!-- TODO -> Implement labels -->
        <!-- <small class="bookGender">Educativo</small> -->
        <p class="bookTitle">{{getBookTitleByLanguage()}}</p>
        <p class="codeIsbn">{{'create_product.interactive_content' | translate}}:
          {{cartProduct.product.interactiveCode}}
        </p>
        <p class="codeIsbn">{{'create_product.product_code_crp' | translate}}: {{getProductMarketCode()}}</p>
        <!-- <p class="license"><small class="bookLicense">Licencia del <b>{{getCurrentDate()|date:'dd \'de\' MMMM \'del\' YYYY':'':'es-CO'}}</b> al <b>{{getDueDate()|date:'dd \'de\' MMMM \'del\' YYYY':'':'es-CO'}}</b></small></p> -->
        <p class="license"><small class="bookLicense">{{'shopping_cart.license_from_sc' | translate}}
            <b>{{getCurrentDate()}}</b> {{'shopping_cart.to_sc' | translate}} <b>{{getDueDate()}}</b></small></p>

        <!-- Delete elment becouse redesign in view -->

        <!-- <p *ngIf="cartProduct.price.currencySymbol != '€'" class="license"><small
            class="bookLicense"><b>{{cartProduct.price.currencySymbol}} {{'shopping_cart.shipping_cost' | translate}}
              {{shippingPrice}}</b></small></p>
        <p *ngIf="cartProduct.price.currencySymbol == '€'" class="license"><small
            class="bookLicense"><b>{{'shopping_cart.shipping_cost' | translate}} {{shippingPrice}}
              {{cartProduct.price.currencySymbol}}</b></small></p> -->
        <br>
        <div class="deleteBook" style="color: #FFA519;">
          <i class="bi bi-trash-fill"></i>
          <a class="deleteBook" style="color: #FFA519;" (click)="deleteItem()">{{'shopping_cart.remove_product_sc' |
            translate}}</a>
        </div>
        <br>
      </div>


      <div class="col-4">
        <small class="deleteBook">{{'create_product.quantity' | translate}}</small>
        <div class="quantity-container">
          <a class="quantity-btn" (click)="removeOne()"><i class="bi bi-dash"></i></a>
          <input disabled class="shop-cart-quantity-input" type="number" tep="1" min="1"
            [ngModel]="cartProduct.quantity">
          <a class="quantity-btn" (click)="addOne()"><i class="bi bi-plus"></i></a>
        </div>
      </div>
    </div>
    <div class="row dataBook costBook">
      <div class="col">
        <small class="deleteBook">{{'shopping_cart.shipping_cost' | translate}}</small>
        <p *ngIf="cartProduct.price.currencySymbol != '€'"><b>{{cartProduct.price.currencySymbol}} {{
            (cartProduct.shippingPrice) | number:'1.2-4':'es-CO'}}</b></p>
        <p *ngIf="cartProduct.price.currencySymbol == '€'"><b>{{ cartProduct.shippingPrice | number:'1.2-4':'es-CO'}}
            {{cartProduct.price.currencySymbol}}</b></p>
      </div>
      <div class="col">
        <small class="deleteBook">{{'create_product.unit_price' | translate}}</small>
        <p *ngIf="cartProduct.price.currencySymbol != '€'"> <b>{{cartProduct.price.currencySymbol}}
            {{getUnitPriceAmountWithoutTaxes() | number:'1.2-4':'es-CO'}}</b></p>
        <p *ngIf="cartProduct.price.currencySymbol == '€'"> <b>{{getUnitPriceAmountWithoutTaxes() |
            number:'1.2-4':'es-CO'}}{{cartProduct.price.currencySymbol}}</b></p>
      </div>
      <div class="col">
        <small class="deleteBook">{{'create_product.tax_percentage_crp' | translate}}</small>
        <p><b>{{ cartProduct.price.taxPercentage | number:'1.2-4':'es-CO'}}</b></p>
      </div>
      <div class="col">
        <small class="deleteBook">{{'create_product.tax_crp' | translate}}</small>
        <p><b>{{ (cartProduct.price.taxes * cartProduct.quantity) | number:'1.2-4':'es-CO'}}</b></p>
      </div>
      <div class="col">
        <small class="deleteBook">{{'create_product.subtotal_price' | translate}}</small>
        <p *ngIf="cartProduct.price.currencySymbol != '€'"> <b>{{cartProduct.price.currencySymbol}}
            {{getTotalAmountWithoutTaxes() | number:'1.2-4':'es-CO'}}</b></p>
        <p *ngIf="cartProduct.price.currencySymbol == '€'"> <b>{{getTotalAmountWithoutTaxes() |
            number:'1.2-4':'es-CO'}}{{cartProduct.price.currencySymbol}}</b></p>
      </div>
    </div>
    <div class="row">
      <div class="col price">
        <small class="totalPrice">{{'create_product.total_price' | translate}}</small>
        <div class="col">
          <p *ngIf="cartProduct.price.currencySymbol != '€'"> <b class="totalPrice">{{cartProduct.price.currencySymbol}}
              {{getTotalAmountWithTaxes() | number:'1.2-4':'es-CO'}}</b></p>
          <p *ngIf="cartProduct.price.currencySymbol == '€'"> <b class="totalPrice">{{getTotalAmountWithTaxes() |
              number:'1.2-4':'es-CO'}}{{cartProduct.price.currencySymbol}}</b></p>
        </div>

      </div>
    </div>
  </div>

</div>
<br>