import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  sessionLanguageKey: string = "selectedLanguage";

  constructor(translate: TranslateService) {
    const appLanguage = sessionStorage.getItem(this.sessionLanguageKey) || env.default_language;

    translate.addLangs(env.languages_supported);
    translate.setDefaultLang(appLanguage);
    translate.use(appLanguage);
  }
}
