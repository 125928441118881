import { Component, ViewChild } from '@angular/core';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { User } from '../../models/user.model';
import { CreateUsersComponent } from '../create-users/create-users.component';
import { EditUsersComponent } from '../edit-users/edit-users.component';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent {

  usersTableLength: number;
  displayedColumns: string[] = ['name', 'email', 'isSpecificEditorial', 'editorial', 'roles', 'language', 'countryCode', 'actions'];
  dataSource = new MatTableDataSource<User>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private readonly deleteUserObserver = {
    next: (data: CommonResponse<any>) => this.deleteUserNext(data),
    error: (error: CommonResponse<any>) => this.deleteUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly getAllUsersObserver = {
    next: (data: CommonResponse<any>) => this.getAllUsersNext(data),
    error: (error: CommonResponse<any>) => this.getAllUsersError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _userService: UserService,
    private _dialog: MatDialog) 
    {
      this._pageLoadingService.showLoadingGif();
      this._userService.getAllUsers()
        .subscribe(this.getAllUsersObserver);
    }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  deleteUserNext(data: CommonResponse<any>) {
    this._pageLoadingService.showLoadingGif();
    this._snackbarService.openStandardSnackBar(`deleteUserNext`);
  }

  deleteUserError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(`deleteUserError`);
  }

  getAllUsersNext(data: CommonResponse<any>) {
    this.dataSource.data = data.data.users;
  }

  getAllUsersError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getAllUsersError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getAllUsersError');
    }
  }

  showDeleteUserConfirmation(userEmail: string, userId: number) {
    const data: DialogData = {
      title: 'Confirmar eliminación',
      message: `¿Estás seguro de que deseas eliminar la cuenta ${userEmail}?`,
      userInteractResult: false
    };

    const confirmDeleteDialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: '350px',
      data: data
    });

    confirmDeleteDialogRef.afterClosed().subscribe(result => {
      this._pageLoadingService.showLoadingGif();

      if(result) {
        let usersListCopy = [...this.dataSource.data];

        const deletedIndex = usersListCopy.findIndex(u => u.userId == userId);
        usersListCopy.splice(deletedIndex, 1);
        this.dataSource.data = usersListCopy;

        this._userService.deleteUser(userId)
          .subscribe(this.deleteUserObserver);
      }

      this._pageLoadingService.hideLoadingGif();
    });
  }

  addUser(){
    const confirmPurchaseDialogRef = this._dialog.open(CreateUsersComponent, {
      height: '65%',
      width: '50%',
    });
  }

  showEditUserConfirmation(userEmail: string, userId: number) {
    const editUserDialogRef = this._dialog.open(EditUsersComponent, {
      height: '65%',
      width: '50%',
      data: { userId: userId } // Pasar userId como parte de la configuración
    });
  }

}
