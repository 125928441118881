<div class="cookiesBox" *ngIf="!cookiesAccepted"> 
    <div class="cookiesText">{{'Cookies.info' | translate}}</div>
    <div class="cookiesButtons">
        <button class="btnAccept" type="button" (click)="acceptCookies()">
            {{ 'Cookies.accept' | translate}}
        </button>
        <button class="btnDeny" type="button" (click)="denyCookies()">
            {{ 'Cookies.deny' | translate }}
        </button>
    </div>
    <div class="cookiesLink">
        <a class="footer-link disabled-link" [routerLink]="['customer-services/policy']"
            routerLinkActive="router-link-active" routerLinkActive="router-link-active" disabled>
            {{'Cookies.see_terms' | translate}}
        </a>
    </div>
</div>