import { AbstractControl, ValidatorFn } from "@angular/forms";

const requiredFileType = function(fileType: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    const file = control.value;

    if(file !== null && file !== undefined && file !== "") {

      const fileExtension = file?.split('.')[1].toLowerCase();

      if(fileType.toLowerCase() !== fileExtension)
        return {requiredFileType: true};
    }

    return null;
  };
}

export { requiredFileType };
