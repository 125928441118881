<div class="title">{{'Files.files_title' | translate}}</div>
<div class="principalContainer d-flex flex-column align-items-center">
  <div class="filesContainer">
    <form [formGroup]="fileForm">

      <input type="file" formControlName="file" (change)="onFileChange($event)" name="file" id="fileInput">

      <div class="row">
        <div class="col-6 d-flex flex-column justify-content-center align-items-center ">
          <label for="licence">{{'Files.version_select' | translate}}</label>
          <input class="versionInput" type="text" formControlName="version">
        </div>

        <div class="col-6 d-flex flex-column justify-content-center align-items-center">
          <label for="licence" id="licence">{{'Files.language_select' | translate}}</label>
          <mat-form-field appearance="fill" class="dropdown end">
            <mat-label>{{'Files.language' | translate}}</mat-label>
            <mat-select formControlName="languaje">
              <mat-option value="en">
                {{'Files.english' | translate}}
              </mat-option>
              <mat-option value="es">
                {{'Files.spanish' | translate}}
              </mat-option>
              <mat-option value="pt">
                {{'Files.Portuguese' | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="buttonContainer">
        <div class="mat-raised-button mat-button-base lg-i3book-button submit" (click)="onSubmit()">{{'Files.file_add' | translate}}</div>
      </div>
    </form>

  </div>
  <div class="tableContainer">
    <table class="table table-striped col-1">
      <thead>
        <tr>
          <th> {{'Files.file_name' | translate}} </th>
          <th> {{'Files.file_version' | translate}} </th>
          <th> {{'Files.file_language' | translate}} </th>
          <th> {{'Files.file_actions' | translate}} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let doc of documents">
          <td>{{doc.name}}</td>
          <td>{{doc.version}}</td>
          <td>{{doc.language}}</td>
          <td>
            <i (click)="deleteDocument(doc.id)" data-toggle="modal" data-target="#modalConfirmation"
              class="bi bi-trash-fill"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>