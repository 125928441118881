<div class="row">
  <div class="row headerListBook">
    <div class="col-5 p-2">
      <b class="titleListBook">{{'users.title' | translate}}</b>
    </div>
    <div class="col-7"></div>
    <br>
  </div>
  <div class="row">
    <div class="col-md-9">
      <p class="descriptionViewListBook">
        {{'users.desc' | translate}}
      </p>
    </div>
    <div class="col-md-3 addUserButtonDiv">
      <button class="addUserButton" (click)="addUser()"><i class="bi bi-person-plus-fill"></i> {{'users.add_user' |
        translate}}</button>
    </div>
  </div>
  <br>
  <div class="col-12">
    <div class="mat-elevation-z8 w-100">
      <table class="table table-striped col-1" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> {{'auth.register.name' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> {{'auth.register.email' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="isSpecificEditorial">
          <th mat-header-cell *matHeaderCellDef> {{'users.belongs_to_editorial' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.isSpecificEditorial == 1 ? 'Sí' : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="editorial">
          <th mat-header-cell *matHeaderCellDef> {{'users.editorial' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.editorial != "" ? element.editorial : ' - '}} </td>
        </ng-container>

        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef> {{'users.roles' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.roles.join(", ")}} </td>
        </ng-container>

        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef> {{'create_product.language_crp' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.language}} </td>
        </ng-container>

        <ng-container matColumnDef="countryCode">
          <th mat-header-cell *matHeaderCellDef> {{'create_product.country_crp' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.countryCode}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> {{'create_product.actions_crp' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <!-- <button mat-raised-button><i class="bi bi-pencil-fill"></i></button> -->
            <button mat-raised-button (click)="showDeleteUserConfirmation(element.email, element.userId)" class="m-1">
              <i data-toggle="modal" data-target="#modalConfirmation" class="bi bi-trash-fill"></i></button>
            <button mat-raised-button (click)="showEditUserConfirmation(element.email, element.userId)" class="m-1">
              <i data-toggle="modal" data-target="#modalConfirmation" class="bi bi-pencil-square"></i></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data-centered" colspan="8">{{'users.no_users_msg' | translate}}</td>
        </tr>
      </table>

      <mat-paginator [pageSize]="10" [length]="usersTableLength" showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </div>
</div>