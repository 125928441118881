import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaypalPaymentService {

  constructor(private _httpClient: HttpClient,
    private _cookieTokenService: CookieTokenService) { }

  startPayment() {

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);

    return this._httpClient.post(`${env.url_api}/${env.api_version}/payment/paypal/initial-request`, null, { observe: 'response' as 'response', headers: headers})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
    }));
  }

  getPaymentConfirmation(paymentId: string, payerId: string) {

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);
    const request = {
      paymentId: paymentId,
      PayerID: payerId,
    };

    return this._httpClient.post(`${env.url_api}/${env.api_version}/payment/paypal/confirm-payment`, request, { observe: 'response' as 'response', headers: headers})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
    }));
  }
}
