import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Book } from '../../models/book.model';

@Component({
  selector: 'app-book-details-information',
  templateUrl: './book-details-information.component.html',
  styleUrls: ['./book-details-information.component.scss'],
})
export class BookDetailsInformationComponent implements OnInit {
  @Input() book: Book;
  sizeDisplay: string;

  @Output() pageChange: EventEmitter<number>;

  page: number = 1;
  readonly breakpoint = this.breakpointObserver
    .observe([Breakpoints.Web, Breakpoints.XSmall]);

  booksData: Book[];

  constructor(
    private _snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpoint.subscribe(() =>
      this.mediaQuery()
    );
  }

  ngOnInit(): void {}

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  addToCart(book: Book) {
    this.openSnackBar('Libro añadido al carrito', 'ok');
  }

  getBookTitleByLanguage(lang: string, id: number) {
    const book = this.booksData.find((x) => x.id == id);

    if (book != null && book != undefined) {
      return book.contents.find((x) => x.language == lang)?.title;
    } else {
      return 'empty resume';
    }
  }

  getBookResumeByLanguage(lang: string, id: number) {
    const book = this.booksData.find((x) => x.id == id);

    if (book != null && book != undefined) {
      return book.contents.find((x) => x.language == lang)?.resume;
    } else {
      return 'empty resume';
    }
  }
  mediaQuery() {
    if(this.breakpointObserver.isMatched(Breakpoints.Web))
      this.sizeDisplay = 'web';

    if(this.breakpointObserver.isMatched(Breakpoints.XSmall))
      this.sizeDisplay = 'phone';
  }
}
