<body>
  <div class="container row">
    <div class="containerLeft col-10 col-md-6 justify-content-between">
      <div id="loginContainer">
        <div class="logo" [ngClass]="{ 'logoQr': qrCodeSvgSafe != null && qrCodeSvgSafe != undefined }">
          <ng-container *ngIf="countryName === ''">
            <img src="" class="mb-3 col-9" style="margin-top: 12rem !important" />
          </ng-container>
          <ng-container *ngIf="countryName !== 'Colombia' && countryName !== '' && qrCodeSvgSafe == null">
            <img src="../../../../assets/img/png/gridmark-login.png" class="gridmark">
            <img src="../../../../assets/img/png/watermark_gridmark.png" class="watermarkGridmark">
            <img src="../../../../assets/img/png/watermark_gridmark.png" class="watermarki3book2">
          </ng-container>
          <ng-container *ngIf="countryName === 'Colombia' && qrCodeSvgSafe == null">
            <img src="../../../../assets/img/png/logo-white.png" class="i3book" />
            <img src="../../../../assets/img/png/watermark_i3book.png" class="watermarki3book" />
            <img src="../../../../assets/img/png/watermark_i3book.png" class="watermarki3book2" />
          </ng-container>
          <div *ngIf="qrCodeSvgSafe != null && qrCodeSvgSafe != undefined">
            <div class="container-qr text-center">
              <div class="row">
                <p>{{'auth.login.get_qr_instruction' | translate}}</p>
              </div>
              <div class="row">
                <div class="col">
                  <div [innerHTML]="qrCodeSvgSafe"></div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="loginButtons">
                    <input [routerLink]="['/login']" routerLinkActive="router-link-active"  type="button" class="sendButtonContinue" value="{{'auth.request_qr.continue' | translate}}">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="qrCodeSvgSafe == null" class="text-image">
          <p class="imageCaption">
            {{ "auth.login.intro_text" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="containerRight col-10 col-md-6 background-login-form py-5 bg-white">
      <div class="login">
        <p class="text-white titleLogin">
          {{ "auth.register.welcome" | translate }}
        </p>
        <div class="subtitle">
          <p class="fw-lighter text-white">
            {{ "auth.register.enter_data" | translate }}
          </p>
        </div>
        <form action="">
          <div class="inputLogin pr-2 pb-2">
            <input class="inputslogin" [(ngModel)]="email" type="email" name="email" id="email"
              placeholder="{{ 'auth.login.user_name_lbl' | translate }}" />
            <div *ngIf="isInvalidEmail() && showErrorMessages" class="negative-feedback">
              <p>{{ "auth.login.user_name_validation" | translate }}.</p>
            </div>
            <input class="inputslogin" [(ngModel)]="password" type="password" name="password" id="password"
              placeholder="{{ 'auth.login.password_lbl' | translate }}" />
            <div *ngIf="isInvalidPassword() && showErrorMessages" class="negative-feedback">
              <p>{{ "auth.login.password_validation" | translate }}.</p>
            </div>
          </div>
          <div class="hintText">
            <p class="">
              <a [routerLink]="['/users/register']" class="text-reset">{{
                "auth.login.get_register_question" | translate
                }}</a>
            </p>
          </div>
          <div class="loginButtons" style="margin-top: 0px">
            <button class="sendButton" type="submit" (click)="requestQrCode()">
              {{ "auth.login.send" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</body>