import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ResponseHelper } from '../../shared/helpers/response.helper';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private _httpClient: HttpClient) {
  }

  getAllLanguages() {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/languages`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }
}
